import React from "react"
import { Helmet } from "react-helmet"

export default function HeadSection({
  title = "",
  description = '"Бесплатный выезд специалиста по Калуге. Мы гарантируем проведение анализа и выплату в день получения отработанного материала. Наша компания скупает катализаторы и сажевые фильтры по высоким ценам до 75% LME."',
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}
