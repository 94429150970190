import React, { Fragment, useRef, useState } from "react"
import { Link } from "gatsby"
import { useRecoilState } from "recoil"
import { callbackModalOpen } from "./atoms"
import { Dialog, Transition } from "@headlessui/react"
import emailjs from "emailjs-com"

export default function ModalCallback() {
  const form = useRef()

  const [city, setCity] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const [open, setOpen] = useRecoilState(callbackModalOpen)
  const [agreed, setAgreed] = useState(true)

  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_44gyk98",
        "template_t53r1gx",
        form.current,
        "user_p3PeVrvCFdq6zxN3MjeOj"
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-10" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            className="modal fade callback-modal show"
            id="callbackModal"
            tabIndex="-1"
            aria-modal="true"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog callback-modal__dialog modal-dialog-centered">
              <div className="modal-content callback-modal__content">
                <div className="callback-modal__header">
                  <Dialog.Title as="div" className="callback-modal__title">
                    Обратная связь
                  </Dialog.Title>
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className="callback-modal__close"
                    data-dismiss="modal"
                  >
                    <span />
                  </button>
                </div>
                <div className="callback-modal__body">
                  <form
                    ref={form}
                    onSubmit={sendEmail}
                    className="ajax_form af_example offer-form"
                  >
                    <div className="offer-form__row">
                      <input
                        value={city}
                        onChange={e => setCity(e.target.value)}
                        type="text"
                        className="offer-form__input"
                        name="city"
                        placeholder="Ваш город"
                      />
                      <input
                        value={phone}
                        onChange={e => {
                          let digits = e.target.value.replace(/\D/g, "")
                          setPhone(digits)
                        }}
                        type="text"
                        className="offer-form__input"
                        name="phone"
                        placeholder="Номер телефона"
                        required
                      />
                    </div>
                    <textarea
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      placeholder="Сообщение"
                      name="message"
                      className="offer-form__input offer-form__textarea"
                    />
                    <label className="delivery-form__personal">
                      <input
                        type="checkbox"
                        required
                        checked={agreed}
                        onClick={() => setAgreed(!agreed)}
                      />
                      <span className="delivery-form__personal-check" />
                      <span className="delivery-form__personal-text">
                        Я согласен с{" "}
                        <Link
                          to="/politika-konfidenczialnosti"
                          className="footer-col-list__link"
                        >
                          условиями обработки
                        </Link>{" "}
                        моих персональных данных
                      </span>
                    </label>
                    <div className="offer-form__button-wrap">
                      <button
                        type="submit"
                        className="offer-form__button button button--solid-orange button--shadow-white"
                      >
                        Отправить
                      </button>
                    </div>

                    <input
                      type="hidden"
                      name="af_action"
                      value="d5c7fee2fddbcbeb4cfc8c462b1bc8b2"
                    />
                    <input
                      type="text"
                      name="org"
                      value=""
                      className="_org"
                      style={{
                        visibility: "hidden",
                        height: "0",
                        width: "0",
                        padding: "0",
                        border: "none",
                      }}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}
