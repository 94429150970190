export function citySwitchTitle(param) {
  switch (param) {
    case "Калуга":
      return "Прием катализаторов и сажевых фильтров в Калуге"
    case "Брянск":
      return "Прием катализаторов и сажевых фильтров в Брянске"
    case "Тула":
      return "Прием катализаторов и сажевых фильтров в Туле"
    case "Воронеж":
      return "Прием катализаторов и сажевых фильтров в Воронеже"
    case "Белгород":
      return "Прием катализаторов и сажевых фильтров в Белгороде"
    case "Иваново":
      return "Прием катализаторов и сажевых фильтров в Иваново"
    case "Смоленск":
      return "Прием катализаторов и сажевых фильтров в Смоленске"
    case "Орел":
      return "Прием катализаторов и сажевых фильтров в Орле"
    case "Курск":
      return "Прием катализаторов и сажевых фильтров в Курске"
    case "Тверь":
      return "Прием катализаторов и сажевых фильтров в Твери"
    case "Саратов":
      return "Прием катализаторов и сажевых фильтров в Саратове"
    case "Липецк":
      return "Прием катализаторов и сажевых фильтров в Липецке"
    case "Владимир":
      return "Прием катализаторов и сажевых фильтров во Владимире"
    default:
      return "Прием катализаторов и сажевых фильтров в Калуге"
  }
}
export function citySwitchDescription(param) {
  switch (param) {
    case "Калуга":
      return "Бесплатный выезд специалиста по Калуге и области."
    case "Брянск":
      return "Бесплатный выезд специалиста по Брянску и области."
    case "Тула":
      return "Бесплатный выезд специалиста по Туле и области."
    case "Воронеж":
      return "Бесплатный выезд специалиста по Воронежу и области."
    case "Белгород":
      return "Бесплатный выезд специалиста по Белгороду и области."
    case "Иваново":
      return "Бесплатный выезд специалиста по Иваново и области."
    case "Смоленск":
      return "Бесплатный выезд специалиста по Смоленску и области."
    case "Орел":
      return "Бесплатный выезд специалиста по Орлу и области."
    case "Курск":
      return "Бесплатный выезд специалиста по Курску и области."
    case "Тверь":
      return "Бесплатный выезд специалиста по Твери и области."
    case "Саратов":
      return "Бесплатный выезд специалиста по Саратову и области."
    case "Липецк":
      return "Бесплатный выезд специалиста по Липецку и области."
    case "Владимир":
      return "Бесплатный выезд специалиста по Владимиру и области."
    default:
      return "Бесплатный выезд специалиста по Калуге и области."
  }
}

export function citySwitchSubtitle(param) {
  switch (param) {
    case "Калуга":
      return "Выезжаем по Калуге и всей области"
    case "Брянск":
      return "Выезжаем по Брянску и всей области"
    case "Тула":
      return "Выезжаем по Туле и всей области"
    case "Воронеж":
      return "Выезжаем по Воронежу и всей области"
    case "Белгород":
      return "Выезжаем по Белгороду и всей области"
    case "Иваново":
      return "Выезжаем по Иваново и всей области"
    case "Смоленск":
      return "Выезжаем по Смоленску и всей области"
    case "Орел":
      return "Выезжаем по Орлу и всей области"
    case "Курск":
      return "Выезжаем по Курску и всей области"
    case "Тверь":
      return "Выезжаем по Твери и всей области"
    case "Саратов":
      return "Выезжаем по Саратову и всей области"
    case "Липецк":
      return "Выезжаем по Липецку и всей области"
    case "Владимир":
      return "Выезжаем по Владимиру и всей области"
    default:
      return "Выезжаем по Калуге и всей области"
  }
}

export function citySwitchTelNumber(param) {
  switch (param) {
    case "Калуга":
      return "89023903705"
    case "Брянск":
      return "89023915132"
    case "Тула":
      return "89105560990"
    case "Воронеж":
      return "89106026319"
    case "Белгород":
      return "89026965333"
    case "Иваново":
      return "89023903705"
    case "Смоленск":
      return "89016359434"
    case "Орел":
      return "89533350222"
    case "Курск":
      return "89533350222"
    case "Тверь":
      return "89005730995"
    case "Саратов":
      return "89023903705"
    case "Липецк":
      return "89106026319"
    case "Владимир":
      return "89533341766"
    default:
      return "88002506625"
  }
}

export function citySwitchTelNumberStr(param) {
  switch (param) {
    case "Калуга":
      return "8 (902) 390-37-05"
    case "Брянск":
      return "8 (902) 391-51-32"
    case "Тула":
      return "8 (910) 556-09-90"
    case "Воронеж":
      return "8 (910) 602-63-19"
    case "Белгород":
      return "8 (902) 696-53-33"
    case "Иваново":
      return "8 (902) 390-37-05"
    case "Смоленск":
      return "8 (901) 635-94-34"
    case "Орел":
      return "8 (953) 335-02-22"
    case "Курск":
      return "8 (953) 335-02-22"
    case "Тверь":
      return "8 (900) 573-09-95"
    case "Саратов":
      return "8 (902) 390-37-05"
    case "Липецк":
      return "8 (910) 602-63-19"
    case "Владимир":
      return "8 (953) 334-17-66"
    default:
      return "8 (800) 250-66-25"
  }
}

export function citySwitchTelNumberCut(param) {
  switch (param) {
    case "Калуга":
      return "79023903705"
    case "Брянск":
      return "79023915132"
    case "Тула":
      return "79105560990"
    case "Воронеж":
      return "79106026319"
    case "Белгород":
      return "79026965333"
    case "Иваново":
      return "79023903705"
    case "Смоленск":
      return "79016359434"
    case "Орел":
      return "79533350222"
    case "Курск":
      return "79533350222"
    case "Тверь":
      return "79005730995"
    case "Саратов":
      return "79023903705"
    case "Липецк":
      return "79106026319"
    case "Владимир":
      return "79533341766"
    default:
      return "79023903705"
  }
}

export function citySwitchAddress1(param) {
  switch (param) {
    case "Калуга":
      return "Калуга, Россия, 248032"
    case "Брянск":
      return "Брянск, Россия, 241028"
    case "Тула":
      return "Тула, Россия, 300045"
    case "Воронеж":
      return "Воронеж, Россия, 394016"
    case "Белгород":
      return "Белгород, Россия, 308006"
    case "Иваново":
      return "Иваново, Россия, 153034"
    case "Смоленск":
      return "Смоленск, Россия, 214018"
    case "Орел":
      return "Орёл, Россия, 302001"
    case "Курск":
      return "Курск, Россия, 305000"
    case "Тверь":
      return "Тверь, Россия, 170026"
    case "Саратов":
      return "Саратов, Россия, 410028"
    case "Липецк":
      return "Липецк, Россия, 398002"
    case "Владимир":
      return "Владимир, Россия, 600000"
    default:
      return "Калуга, Россия, 248032"
  }
}

export function citySwitchAddress2(param) {
  switch (param) {
    case "Калуга":
      return "Турынинская улица 8А"
    case "Брянск":
      return "Карачижская улица, 103Б"
    case "Тула":
      return "Рязанская улица, 54"
    case "Воронеж":
      return "Ленинградская улица, 29Г"
    case "Белгород":
      return "улица Серафимовича, 72А"
    case "Иваново":
      return "улица Смирнова, 105"
    case "Смоленск":
      return "проспект Гагарина, 26"
    case "Орел":
      return "Комсомольская улица, 53"
    case "Курск":
      return "Сосновская улица, 5"
    case "Тверь":
      return "улица Горького, 35"
    case "Саратов":
      return "Вольская улица, 61"
    case "Липецк":
      return "Липовская улица, 8"
    case "Владимир":
      return "улица Семашко, 8"
    default:
      return "Турынинская улица 8А"
  }
}

export function citySwitchAddress(param) {
  switch (param) {
    case "Калуга":
      return "Калуга, Турынинская улица 8А"
    case "Брянск":
      return "Брянск, Карачижская улица, 103Б"
    case "Тула":
      return "Тула, Рязанская улица, 54"
    case "Воронеж":
      return "Воронеж, Ленинградская улица, 29Г"
    case "Белгород":
      return "Белгород, улица Серафимовича, 72А"
    case "Иваново":
      return "Иваново, улица Смирнова, 105"
    case "Смоленск":
      return "Смоленск, проспект Гагарина, 26"
    case "Орел":
      return "Орел, Комсомольская улица, 53"
    case "Курск":
      return "Курск, Сосновская улица, 5"
    case "Тверь":
      return "Тверь, улица Горького, 35"
    case "Саратов":
      return "Саратов, Вольская улица, 61"
    case "Липецк":
      return "Липецк, Липовская улица, 8"
    case "Владимир":
      return "Владимир, улица Семашко, 8"
    default:
      return "Калуга, Турынинская улица 8А"
  }
}

export function citySwitchMapCenter(param) {
  switch (param) {
    case "Калуга":
      return [54.515304, 36.34183]
    case "Брянск":
      return [53.221543, 34.324699]
    case "Тула":
      return [54.174402, 37.641647]
    case "Воронеж":
      return [51.659729, 39.250409]
    case "Белгород":
      return [50.584667, 36.624494]
    case "Иваново":
      return [56.986011, 41.008084]
    case "Смоленск":
      return [54.766521, 32.044433]
    case "Орел":
      return [52.955715, 36.064161]
    case "Курск":
      return [51.728682, 36.185631]
    case "Тверь":
      return [56.867213, 35.903434]
    case "Саратов":
      return [51.529259, 46.022381]
    case "Липецк":
      return [52.612014, 39.589949]
    case "Владимир":
      return [56.128957, 40.388848]
    default:
      return [54.515304, 36.34183]
  }
}

export function citySwitchMapLink(param) {
  switch (param) {
    case "Калуга":
      return "https://yandex.ru/maps/-/CCUymGdqGC"
    case "Брянск":
      return "https://yandex.ru/maps/-/CCUymGhktB"
    case "Тула":
      return "https://yandex.ru/maps/-/CCUymGtIDA"
    case "Воронеж":
      return "https://yandex.ru/maps/-/CCUymGtb-C"
    case "Белгород":
      return "https://yandex.ru/maps/-/CCUymKbKxC"
    case "Иваново":
      return "https://yandex.ru/maps/-/CCUymOUaLC"
    case "Смоленск":
      return "https://yandex.ru/maps/-/CCUymWtIhA"
    case "Орел":
      return "https://yandex.ru/maps/-/CCUym0AZgA"
    case "Курск":
      return "https://yandex.ru/maps/-/CCUym0QNGD"
    case "Тверь":
      return "https://yandex.ru/maps/-/CCUym0rkhC"
    case "Саратов":
      return "https://yandex.ru/maps/-/CCUym0C5tA"
    case "Липецк":
      return "https://yandex.ru/maps/-/CCUym0SAPA"
    case "Владимир":
      return "https://yandex.ru/maps/-/CCUym0cLxC"
    default:
      return "https://yandex.ru/maps/-/CCUymGdqGC"
  }
}
