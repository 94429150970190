import React from "react"
import PropTypes from "prop-types"

import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

import "../assets/css/layout.css"
import Footer from "./Home/Footer"
import ModalCallback from "./Home/ModalCallback"
import ModalCities from "./Home/ModalCities"
import { RecoilRoot } from "recoil"

const queryClient = new QueryClient()

const Layout = ({ children, city }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <div className="layout">
          <main className="main">{children}</main>
          <Footer city={city} />
          <ModalCallback />
          <ModalCities />
        </div>
        <ReactQueryDevtools />
      </RecoilRoot>
    </QueryClientProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
