import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"

import { Link } from "gatsby"

import { useRecoilState } from "recoil"
import { citiesModalOpen } from "./atoms"

import kalugaWebp from "../../assets/img/cities/kaluga.webp"
import kalugaJpg from "../../assets/img/cities/kaluga.jpg"
import tulaWebp from "../../assets/img/cities/tula.webp"
import tulaJpg from "../../assets/img/cities/tula.jpg"
import smolenskWebp from "../../assets/img/cities/tula.webp"
import smolenskJpg from "../../assets/img/cities/tula.jpg"
import bryanskWebp from "../../assets/img/cities/bryansk.webp"
import bryanskJpg from "../../assets/img/cities/bryansk.jpg"
import orelWebp from "../../assets/img/cities/orel.webp"
import orelJpg from "../../assets/img/cities/orel.jpg"
import voronejWebp from "../../assets/img/cities/voronej.webp"
import voronejJpg from "../../assets/img/cities/voronej.jpg"
import belgorodWebp from "../../assets/img/cities/belgorod.webp"
import belgorodJpg from "../../assets/img/cities/belgorod.jpg"
import kurskWebp from "../../assets/img/cities/kursk.webp"
import kurskJpg from "../../assets/img/cities/kursk.jpg"
import tverWebp from "../../assets/img/cities/tver.webp"
import tverJpg from "../../assets/img/cities/kursk.jpg"
import saratovWebp from "../../assets/img/cities/saratov.webp"
import saratovJpg from "../../assets/img/cities/saratov.jpg"
import lipetskWebp from "../../assets/img/cities/lipetsk.webp"
import lipetskJpg from "../../assets/img/cities/lipetsk.jpg"
import ivanovoWebp from "../../assets/img/cities/ivanovo.webp"
import ivanovoJpg from "../../assets/img/cities/ivanovo.jpg"

export default function ModalCities() {
  const [open, setOpen] = useRecoilState(citiesModalOpen)
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-10" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            className="modal fade cities-modal callback-modal show overflow-auto"
            id="citiesModal"
            tabIndex="-1"
            aria-modal="true"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog callback-modal__dialog modal-dialog-centered">
              <div className="modal-content callback-modal__content">
                <div className="callback-modal__header">
                  <Dialog.Title as="div" className="callback-modal__title">
                    Города
                  </Dialog.Title>

                  <button
                    type="button"
                    className="callback-modal__close"
                    data-dismiss="modal"
                    onClick={() => setOpen(false)}
                  >
                    <span />
                  </button>
                </div>
                <div className="callback-modal__body">
                  <div className="cities-blocks">
                    <div className="cities-block">
                      <div className="cities-block__img">
                        <picture>
                          <source srcSet={kalugaWebp} type="image/webp" />
                          <source srcSet={kalugaJpg} type="image/jpeg" />
                          <img src={kalugaJpg} alt="КаталикАвто в Калуге" />
                        </picture>
                      </div>
                      <div className="cities-block__right">
                        <div className="cities-block__header">
                          <Link to="/kaluga">Калуга и Калужская область</Link>
                        </div>
                        <div className="cities-block__descr">
                          Пункт приема, выездные менеджеры, цех переработки,
                          магазин з/ч для выхлопных систем
                        </div>
                        <div className="cities-block__phones">
                          <div className="cities-block__phone">
                            <a href="tel:89106022147">8 (910) 602-21-47</a>
                            <span>(Иван)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:89605250243">8 (960) 525-02-43</a>
                            <span>(Андрей)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:88002506625">8 (800) 250-66-25</a>
                            <span>(звонок бесплатный)</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="cities-block">
                      <div className="cities-block__img">
                        <picture>
                          <source srcSet={tulaWebp} type="image/webp" />
                          <source srcSet={tulaJpg} type="image/jpeg" />
                          <img src={tulaJpg} alt="КаталикАвто в Туле" />
                        </picture>
                      </div>
                      <div className="cities-block__right">
                        <div className="cities-block__header">
                          <Link to="/tula">Тула и Тульская область</Link>
                        </div>
                        <div className="cities-block__descr">
                          Пункт приема, выездные менеджеры
                        </div>
                        <div className="cities-block__phones">
                          <div className="cities-block__phone">
                            <a href="tel:89207425147">8 (920) 742-51-47</a>
                            <span>(Алексей)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:89207815773">8 (920) 781-57-73</a>
                            <span>(Даниил)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:88002506625">8 (800) 250-66-25</a>
                            <span>(звонок бесплатный)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cities-block">
                      <div className="cities-block__img">
                        <picture>
                          <source srcSet={smolenskWebp} type="image/webp" />
                          <source srcSet={smolenskJpg} type="image/jpeg" />
                          <img
                            src={smolenskJpg}
                            alt="КаталикАвто в Смоленске"
                          />
                        </picture>
                      </div>
                      <div className="cities-block__right">
                        <div className="cities-block__header">
                          <Link to="/smolensk">
                            Смоленск и Смоленская область
                          </Link>
                        </div>
                        <div className="cities-block__descr">
                          Пункт приема, выездные менеджеры
                        </div>
                        <div className="cities-block__phones">
                          <div className="cities-block__phone">
                            <a href="tel:89016359434">8 (901) 635-94-34</a>
                            <span>(Андрей)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:89105560990">8 (910) 556-09-90</a>
                            <span>(Даниил)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:88002506625">8 (800) 250-66-25</a>
                            <span>(звонок бесплатный)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cities-block">
                      <div className="cities-block__img">
                        <picture>
                          <source srcSet={bryanskWebp} type="image/webp" />
                          <source srcSet={bryanskJpg} type="image/jpeg" />
                          <img src={bryanskJpg} alt="КаталикАвто в Брянске" />
                        </picture>
                      </div>
                      <div className="cities-block__right">
                        <div className="cities-block__header">
                          <Link to="/bryansk">Брянск и Брянская область</Link>
                        </div>
                        <div className="cities-block__descr">
                          Пункт приема, выездные менеджеры
                        </div>
                        <div className="cities-block__phones">
                          <div className="cities-block__phone">
                            <a href="tel:89023915132">8 (902) 391-51-32</a>
                            <span>(Андрей)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:88002506625">8 (800) 250-66-25</a>
                            <span>(звонок бесплатный)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cities-block">
                      <div className="cities-block__img">
                        <picture>
                          <source srcSet={orelWebp} type="image/webp" />
                          <source srcSet={orelJpg} type="image/jpeg" />
                          <img src={orelJpg} alt="КаталикАвто в Орле" />
                        </picture>
                      </div>
                      <div className="cities-block__right">
                        <div className="cities-block__header">
                          <Link to="/orel">Орёл и Орловская область</Link>
                        </div>
                        <div className="cities-block__descr">
                          Пункт приема, выездные менеджеры
                        </div>
                        <div className="cities-block__phones">
                          <div className="cities-block__phone">
                            <a href="tel:89533350222">8 (953) 335-02-22</a>
                            <span>(Виктор)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:88002506625">8 (800) 250-66-25</a>
                            <span>(звонок бесплатный)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cities-block">
                      <div className="cities-block__img">
                        <picture>
                          <source srcSet={voronejWebp} type="image/webp" />
                          <source srcSet={voronejJpg} type="image/jpeg" />
                          <img src={voronejJpg} alt="КаталикАвто в Воронеже" />
                        </picture>
                      </div>
                      <div className="cities-block__right">
                        <div className="cities-block__header">
                          <Link to="/voronezh">
                            Воронеж и Воронежская область
                          </Link>
                        </div>
                        <div className="cities-block__descr">
                          Пункт приема, выездные менеджеры
                        </div>
                        <div className="cities-block__phones">
                          <div className="cities-block__phone">
                            <a href="tel:89106026319">8 (910) 602-63-19</a>
                            <span>(Максим)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:88002506625">8 (800) 250-66-25</a>
                            <span>(звонок бесплатный)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cities-block">
                      <div className="cities-block__img">
                        <picture>
                          <source srcSet={belgorodWebp} type="image/webp" />
                          <source srcSet={belgorodJpg} type="image/jpeg" />
                          <img
                            src={belgorodJpg}
                            alt="КаталикАвто в Белгороде"
                          />
                        </picture>
                      </div>
                      <div className="cities-block__right">
                        <div className="cities-block__header">
                          <Link to="/belgorod">
                            Белгород и Белгородская область
                          </Link>
                        </div>
                        <div className="cities-block__descr">
                          Пункт приема, выездные менеджеры
                        </div>
                        <div className="cities-block__phones">
                          <div className="cities-block__phone">
                            <a href="tel:89533350222">8 (953) 335-02-22</a>
                            <span>(Виктор)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:89026965333">8 (902) 696-53-33</a>
                            <span>(Алексей)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:88002506625">8 (800) 250-66-25</a>
                            <span>(звонок бесплатный)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cities-block">
                      <div className="cities-block__img">
                        <picture>
                          <source srcSet={kurskWebp} type="image/webp" />
                          <source srcSet={kurskJpg} type="image/jpeg" />
                          <img src={kalugaJpg} alt="КаталикАвто в Курске" />
                        </picture>
                      </div>
                      <div className="cities-block__right">
                        <div className="cities-block__header">
                          <Link to="/kursk">Курск и Курская область</Link>
                        </div>
                        <div className="cities-block__descr">
                          Пункт приема, выездные менеджеры
                        </div>
                        <div className="cities-block__phones">
                          <div className="cities-block__phone">
                            <a href="tel:89533350222">8 (953) 335-02-22</a>
                            <span>(Виктор)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:89026965333">8 (902) 696-53-33</a>
                            <span>(Алексей)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:88002506625">8 (800) 250-66-25</a>
                            <span>(звонок бесплатный)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cities-block">
                      <div className="cities-block__img">
                        <picture>
                          <source srcSet={tverWebp} type="image/webp" />
                          <source srcSet={tverJpg} type="image/jpeg" />
                          <img src={tverJpg} alt="КаталикАвто в Твери" />
                        </picture>
                      </div>
                      <div className="cities-block__right">
                        <div className="cities-block__header">
                          <Link to="/tver">Тверь и Тверская область</Link>
                        </div>
                        <div className="cities-block__descr">
                          Пункт приема, выездные менеджеры
                        </div>
                        <div className="cities-block__phones">
                          <div className="cities-block__phone">
                            <a href="tel:89005730995">8 (900) 573-09-95</a>
                            <span>(Павел)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:88002506625">8 (800) 250-66-25</a>
                            <span>(звонок бесплатный)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cities-block">
                      <div className="cities-block__img">
                        <picture>
                          <source srcSet={saratovWebp} type="image/webp" />
                          <source srcSet={saratovJpg} type="image/jpeg" />
                          <img src={saratovJpg} alt="КаталикАвто в Саратове" />
                        </picture>
                      </div>
                      <div className="cities-block__right">
                        <div className="cities-block__header">
                          <Link to="/saratov">
                            Саратов и Саратовская область
                          </Link>
                        </div>
                        <div className="cities-block__descr">
                          Пункт приема, выездные менеджеры
                        </div>
                        <div className="cities-block__phones">
                          <div className="cities-block__phone">
                            <a href="tel:+79023903705">8 (902) 390-37-05</a>
                            <span>(Андрей)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:88002506625">8 (800) 250-66-25</a>
                            <span>(звонок бесплатный)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cities-block">
                      <div className="cities-block__img">
                        <picture>
                          <source srcSet={lipetskWebp} type="image/webp" />
                          <source srcSet={lipetskJpg} type="image/jpeg" />
                          <img src={lipetskJpg} alt="КаталикАвто в Липецке" />
                        </picture>
                      </div>
                      <div className="cities-block__right">
                        <div className="cities-block__header">
                          <Link to="/lipetsk">Липецк и Липецкая область</Link>
                        </div>
                        <div className="cities-block__descr">
                          Пункт приема, выездные менеджеры
                        </div>
                        <div className="cities-block__phones">
                          <div className="cities-block__phone">
                            <a href="tel:89106026319">8 (910) 602-63-19</a>
                            <span>(Максим)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:88002506625">8 (800) 250-66-25</a>
                            <span>(звонок бесплатный)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cities-block">
                      <div className="cities-block__img">
                        <picture>
                          <source srcSet={ivanovoWebp} type="image/webp" />
                          <source srcSet={ivanovoJpg} type="image/jpeg" />
                          <img src={ivanovoJpg} alt="КаталикАвто в Иваново" />
                        </picture>
                      </div>
                      <div className="cities-block__right">
                        <div className="cities-block__header">
                          <Link to="/ivanovo">
                            Иваново и Ивановская область
                          </Link>
                        </div>
                        <div className="cities-block__descr">
                          Пункт приема, выездные менеджеры
                        </div>
                        <div className="cities-block__phones">
                          <div className="cities-block__phone">
                            <a href="tel:+79023903705">8 (902) 390-37-05</a>
                            <span>(Андрей)</span>
                          </div>
                          <div className="cities-block__phone">
                            <a href="tel:88002506625">8 (800) 250-66-25</a>
                            <span>(звонок бесплатный)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}
