import { atom } from "recoil"

export const citiesModalOpen = atom({
  key: "citiesModalOpen",
  default: false,
})

export const callbackModalOpen = atom({
  key: "callbackModalOpen",
  default: false,
})
